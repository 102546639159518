import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Meridianposter = () => {
  return (
    <div class="px-5 mini:px-25p pt-100p sm:pt-80p max-w-1000p mx-auto xl:mt-0">
      <h2 class="mb-15p sm:mb-30p text-primary font-bold text-center text-25p sm:text-40p">
        Meridiansystem zur Eigenanlyse
      </h2>
      {/* <h3 class="mb-40p sm:mb-80p text-secondary font-medium text-center text-20p sm:text-25p">{news.subtitle}</h3> */}
      <StaticImage src="../img/Meridianposter.jpg" alt="Merdiianposter" />
    </div>
  );
};
export default Meridianposter;
